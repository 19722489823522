import HeaderFavLogoI from "../static/images/fav.png";
import HeaderLogoI from "../static/images/logo.png";
import NotFoundImageI from "../static/images/404.png";
import blogdetailsbannerbgI from "../static/images/blog-details-banner-bg.png";
import contactbannerbgI from "../static/images/contact-banner-bg.png";
import gamesbannerbgI from "../static/images/games-banner-bg.png";
import indexbannerbgI from "../static/images/index-banner-bg.png";
import btnbgI from "../static/images/btn-bg.png";
import blogpostimage1I from "../static/images/blog-post-image-1.png";
import aboutUsBannerBgI from "../static/images/about-us-banner-bg.png";
import blogBannerBgI from "../static/images/blog-banner-bg.png";
import errorbgI from "../static/images/error-bg.png";
import footerbgI from "../static/images/footer-bg.png";
import populargamebgI from "../static/images/popular-game-bg.png";
import preloaderI from "../static/images/preloader.gif";
import leftArrowIconI from "../static/images/icon/left-arrow.png";
import gamestabicon1I from "../static/images/icon/games-tab-icon-1.png";
import playiconI from "../static/images/icon/play-icon.png";
import rightarrowI from "../static/images/icon/right-arrow.png";
import rightarrow2I from "../static/images/icon/right-arrow-2.png";
import searchiconI from "../static/images/icon/search-icon.png";
import fullScreenI from "../static/images/icon/fullscreen.png";
import exitFullscreenI from "../static/images/icon/exit-fullscreen.png";

export const HeaderFavLogo = HeaderFavLogoI;
export const HeaderLogo = HeaderLogoI;
export const NotFoundImage = NotFoundImageI;
export const blogdetailsbannerbg = blogdetailsbannerbgI;
export const contactbannerbg = contactbannerbgI;
export const gamesbannerbg = gamesbannerbgI;
export const indexbannerbg = indexbannerbgI;
export const btnbg = btnbgI;
export const blogpostimage1 = blogpostimage1I;
export const aboutUsBannerBg = aboutUsBannerBgI;
export const blogBannerBg = blogBannerBgI;
export const errorbg = errorbgI;
export const footerbg = footerbgI;
export const populargamebg = populargamebgI;
export const preloader = preloaderI;
export const leftArrowIcon = leftArrowIconI;
export const gamestabicon1 = gamestabicon1I;
export const playicon = playiconI;
export const rightarrow = rightarrowI;
export const rightarrow2 = rightarrow2I;
export const searchicon = searchiconI;
export const fullscreen = fullScreenI;
export const exitFullscreen = exitFullscreenI;